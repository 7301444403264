<script>
import showdown from 'showdown';

export default {
  name: 'convertMarkdown',

  methods: {
    convertMarkdown(text) {
      if (text && typeof text !== 'undefined') {
        const converter = new showdown.Converter();
        const html = converter.makeHtml(text);
        return html;
      }
      return '';
    },
  },
};
</script>
