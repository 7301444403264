<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'queryUtilityPages',
  watch: {
    utilityPages() {
      this.$store.commit('setUtilityPages', this.utilityPages);
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_GRAPHQL,
    });

    const store = useStore();

    const context = {
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
      },
    };

    const variables = reactive({
      lang: computed(() => store.state.lang.currentLang),
    });

    const queryUtilityPages = gql`
      query ($lang:I18NLocaleCode!) {
        utilities (locale: $lang) {
          data {
            attributes {
              Titel
              slug
            }
          }
        }
      }
    `;
    const { data: utilityPages } = useQuery({
      query: queryUtilityPages,
      variables,
      context,
    });
    return { utilityPages };
  },
};
</script>
